<template>
  <section class="invoice">
    <pageLoader v-if="showpageLoader" :loadingText="'Traitement des données en cours'"/>

    <div class="page-header">
      <h3 class="page-title">
        Liste des Produits
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">E-commerce</a></li>
          <li class="breadcrumb-item active" aria-current="page">Catalogue</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <!-- bouton ajout d'un produit -->
              <div class="col-md-12 col-sm-12">
                <router-link :to="{name:'addCatalogue'}">
                <button type="button" class="btn btn-gradient-success btn-icon-text">
                    <i class="mdi mdi-plus btn-icon-prepend"></i>
                    Ajouter un produit
                    </button>
                   </router-link>
              </div>
            </div>
            <div v-if="isload" class="jumping-dots-loader">
                    <span></span>
                    <span></span>
                    <span></span>
            </div>
            <simple-card
            activeSearch
            activePerpage
            id="popularProduct"
            :items="products"
            :search="Xsearch"
            :loadingMessage="loadingMessage"
            title="Produits"
            @deleteProduct = showAlertDeleteProduct
            col="col-lg-6 col-md-6 col-sm-6 col-12"
            />
          </div><!-- card body -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SimpleCard from '../../../components/pagination/simpleCard.vue';
import pageLoader from '../../../components/pageLoader.vue'

const axios = require ('axios');
export default {
  name: 'catalogue-disabled',
  components: {
    SimpleCard,
    pageLoader
  },
  data () {
    return {
      products: [],
      posts: ['post 1', 'post 2'],
      Xsearch:'',
      currentPage: 1,
      perPage:3,
      isload:true,
      showpageLoader:false,
      loadingMessage:''
    }
  },
  computed: {
    // recherche
    filter(){
      return this.products.filter((products) => {
        return products.name.match (this.search);
       
      })
    },
    rows() {
      return this.products.length
    },
    //pagination
    paginateProducts () {
      return this.products.slice (
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      )
    }
  },
  methods: {
    async getProducts() {
      await axios.get('products').then ((response)=> {
        this.isload =false
      this.products=response.data.result.filter( prod => !prod.isactive );
      console.log ('products result',this.products)
      this.loadingMessage ="il n'y a pas de produit correspondant à la recherche"
    })
    },
    // vérification données
    showDetails(product) { 
      console.log('produits',product)
      const ulid= product.ulid
      this.$router.push({
        name:'catalogueDetails',
        params: {
          catalogueDetails: product,
          ulid }
        })
    },
    showproducts() {
      axios.get('products/')
      // .then(reponse =>console.log(reponse));
      .then((reponse)=>{
        this.posts = reponse.data.result
        console.log (reponse)
      })
    },
    // delete
    async deleteProduct(product) {
      this.showpageLoader = true
      for(let image of product.image) {
        await axios.put(`products/${product.ulid}`, {
          remove_image:`${image.ulid}`
        },
        {
          headers:{
            'X-AUTH-TOKEN': localStorage.getItem('token')
          }
        })
        .then(resPutImage => {console.log(resPutImage)})
        .catch(errPutImage => { console.log(errPutImage)
        })
      }
      axios.put(`products/${product.ulid}`,{
        isactive:false,
      }, {
        headers: {
          'X-AUTH-TOKEN': localStorage.getItem('token')
        }
      })
      .then(
        ()=>{
          this.getProducts()
          this.isload =false
          this.showpageLoader = false
          this.$swal(
              'Supprimé!',
              'Le produit a bien été supprimé',
              'success'
            )
        }
      )
      .catch(errPut=> console.log(errPut))

          // axios.delete(`products/${product.ulid}`,{
          //   headers: {
          //     'X-AUTH-TOKEN':localStorage.getItem('token')
          //   }
          // })
          // .then(()=>{
          //   this.getProducts()
          //   this.isload =false
          // this.showpageLoader = false
          //   this.$swal(
          //       'Supprimé!',
          //       'Le produit a bien été supprimé',
          //       'success'
          //     )
          // }
          // )
          // .catch(err=>console.log(err))
    },
    //edit
    editProduct(product){
      this.$router.push ( {
        name: 'editCatalogue',
        params: { 
          product,
          ulid: product.ulid
         }
      })
    },
    showAlertDeleteProduct(product) {
      this.$swal({
        title: 'Êtes-vous sûr?',
        text: 'Voulez-vous supprimer ce produit?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui, Supprimer',
        cancelButtonText: 'Annuler',
      })
      .then((result) => {
        if (result.value) {
        this.isload =true
          this.deleteProduct(product)
          
        }
      })
    }
    // fin vérification données
  },
  beforeMount () {
    this.loadingMessage = 'En cours de chargement ...'
  },
  async mounted () {
    this.getProducts()
    this.loadingMessage = "En cours de chargement ..."
    // affichage des produits par axios V1
    // this.getProducts()
    
    //affichage produits par store
    // this.$store.dispatch('getProducts')
    // .then(()=> {})
    // .catch(err=> {console.log(err)})
  }
}
</script>